<template>
    <div class="view">
        <van-row>
            <van-col span="24">
                <div class="logo">
                    <img src="../../../assets/images/logo.png" />
                </div>
            </van-col>
        </van-row>
        <template v-if="validEntry">
            <van-row type="flex" justify="center">
                <van-col span="12" class="area">
                    <i class="icon" />
                    <select name="area" v-model="currentCommunity" @change="communityChange" class="select">
                        <option v-for="(item, index) in communityData" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                </van-col>
                <van-col span="12" class="oper">
                    <button class="button vis" @click="goVisitor" />
                    <button class="button face" @click="goFace" />
                </van-col>
            </van-row>
            <van-row>
                <van-col span="24">
                    <div class="qrcode">
                        <div class="ac-loading"><van-loading type="spinner" color="#666" /></div>
                        <div ref="qrcode"></div>
                    </div>
                </van-col>
            </van-row>
            <van-row>
                <van-col span="24">
                    <div class="desc">请将二维码对准设备即可通行</div>
                    <div class="desc">该二维码1小时内有效</div>
                </van-col>
            </van-row>
        </template>
        <van-row v-else type="flex" justify="center" class="error"> 请您登录微信公众号<br /><br />从智能门禁打开 </van-row>
        <van-popup v-model="showFace">
            <div class="popup">
                <div class="facebg">
                    <van-uploader v-model="faceFileList" accept="image/*" :preview-image="true" :max-size="5 * 1024 * 1024" :after-read="afterFaceRead">
                        <div class="facebtn" />
                    </van-uploader>
                    <div class="preview" v-show="showPriview">
                        <div class="priviewcontent">
                            <div class="closewrap">
                                <van-icon name="cross" @click="showPriview = false" />
                            </div>
                            <img class="previewimg" :src="currentFacePath" />
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { Col, Row, Popup, Icon, Uploader, Loading } from 'vant';
    import QRCode from 'qrcodejs2';

    export default {
        components: {
            [Col.name]: Col,
            [Row.name]: Row,
            [Popup.name]: Popup,
            [Icon.name]: Icon,
            [Uploader.name]: Uploader,
            [Loading.name]: Loading,
        },
        data() {
            return {
                isTest: true,
                showFace: false,
                showPriview: false,
                currentFacePath: null, // 主人人脸图像
                currentCommunity: null,
                communityData: [],
                faceFileList: [],
                inParams: {
                    tid: null, // (小区Id)
                    tname: null, // 小区名称
                    dong: null, // (栋号，4位内数字）
                    ho: null, // (房号，4位内数字）
                    ownerPhone: null, //（业主手机号）
                    ownerName: null, //（微信名）
                },
            };
        },
        computed: {
            validEntry() {
                if (this.isTest) {
                    return true;
                }
                // 校验refer
                let referr = document.referrer;
                if (referr == null || referr == '' || (referr.indexOf('xudc.com') == -1 && referr.indexOf('lianfawy.com') == -1)) {
                    return false;
                }
                // 校验参数
                if (!this.inParams.communityId || !this.inParams.dong || !this.inParams.ownerPhone) {
                    return false;
                }
                return true;
            },
        },
        methods: {
            goVisitor() {
                this.$router.push({ path: '/wxapp/visitor', query: this.inParams });
            },
            goFace() {
                this.showFace = true;
                if (this.currentFacePath) {
                    this.showPriview = true;
                }
            },
            afterFaceRead(file) {
                let that = this;
                file.status = 'uploading';
                file.message = '上传中...';
                that.$common.compress(file.content, 800, 0.7).then((val) => {
                    that.request({
                        params: { method: 'post', ...this.inParams, ...{ imageCollection: [val] } },
                        url: '/person/face/upload',
                        callback(code, data) {
                            console.log(code, data);
                            if (data.code == 200) {
                                file.status = 'done';
                                file.message = '人脸录入成功！';
                                that.currentFacePath = file.content;
                                that.showPriview = true;
                                // 隐藏控件预览
                                document.querySelector('.van-uploader__preview').remove();
                            } else {
                                file.status = 'failed';
                                file.message = '人脸录入失败，请重新录入！';
                            }
                        },
                        error() {
                            file.status = 'failed';
                            file.message = '人脸录入失败，请重新录入！';
                        },
                    });
                });
            },
            communityChange() {
                this.inParams.tid = this.currentCommunity;
                this.inParams.tname = this.getCommunityName(this.currentCommunity);
                this.initQrCodeData();
            },
            getCommunityName() {
                if (!this.$common.isEmptyObject(this.communityData)) {
                    try {
                        return this.communityData.filter((element) => {
                            return element.id == this.inParams.tid;
                        })[0].name;
                    } catch (e) {
                        return '-';
                    }
                }
                return '-';
            },
            initCommunityData(callback) {
                let that = this;
                that.request({
                    params: { mobile: this.inParams.ownerPhone },
                    url: '/community/list',
                    callback(code, data) {
                        console.log(code, data);
                        that.communityData = data.result;
                        // 设置小区名称
                        that.inParams.tname = that.getCommunityName(that.inParams.tid);
                        callback();
                    },
                    error() {},
                });
            },
            initQrCodeData() {
                let that = this;
                that.$common.showLoading();
                let params = {
                    community: this.currentCommunity,
                    communityName: this.inParams.tname,
                    dong: this.inParams.dong,
                    ho: this.inParams.ho,
                    ownerName: this.inParams.ownerName,
                    ownerPhone: this.inParams.ownerPhone,
                    role: 1,
                    count: 1,
                    startTime: this.$common.formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                    endTime: this.$common.addDateOfHour(new Date(), 1),
                    createTime: this.$common.formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                };
                that.request({
                    params: { method: 'post', ...params },
                    url: '/test',
                    callback(code, data) {
                        console.log(code, data);
                        if (data.code == 200) {
                            that.creatQrCode(data.result.uuid);
                        } else {
                            that.$refs.qrcode.innerHTML = '生成二维码失败';
                        }
                        that.$common.hideLoading();
                    },
                    error(e) {
                        console.log('生成二维码失败，', e);
                        that.$refs.qrcode.innerHTML = '生成二维码失败';
                        that.$common.hideLoading();
                    },
                });
            },
            creatQrCode(uuid) {
                //清除之前的二维码
                this.$refs.qrcode.innerHTML = '';
                new QRCode(this.$refs.qrcode, {
                    text: uuid, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            initFaceData() {
                let that = this;
                that.currentFacePath = null;
                that.request({
                    params: { method: 'post', ...this.inParams },
                    url: '/person',
                    callback(code, data) {
                        console.log(code, data);
                        if (data.code == 200) {
                            that.currentFacePath = data.result.imageCollection[0];
                        }
                    },
                    error() {},
                });
            },
            ...mapActions(['request']),
        },
        created() {
            this.inParams.tid = this.$route.query.communityId;
            this.inParams.dong = this.$route.query.dong;
            this.inParams.ho = this.$route.query.ho;
            this.inParams.ownerPhone = this.$route.query.ownerPhone;
            this.inParams.ownerName = this.$route.query.ownerName;
            this.currentCommunity = this.inParams.tid;
        },
        mounted() {
            let that = this;
            that.initCommunityData(() => {
                that.initQrCodeData();
            });
            //that.initFaceData();
        },
    };
</script>
<style lang="less" scoped>
    @import '../../../styles/core.less';
    .view {
        background: url('../../../assets/images/background.jpg');
        background-size: 100% 100%;
    }
    .logo {
        padding: 40px 10px 8px;
        text-align: center;
    }
    .logo img {
        width: 243px;
    }
    .area {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .area .icon {
        background: url('../../../assets/images/selectdown.png') no-repeat;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
    }
    .area .select {
        margin-left: 3px;
        background-color: transparent;
        border: solid 0px;
        overflow: hidden;
        font-size: 14px;
        color: white;
        -webkit-appearance: initial;
        min-width: 140px;
    }
    .oper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .oper .button {
        height: 27px;
        width: 81px;
        background: transparent;
        border: none;
        background-size: 100% 100%;
    }
    .oper .button.vis {
        background: url('../../../assets/images/visitor.png') no-repeat;
        background-size: 100% 100%;
    }
    .oper .button.face {
        margin-left: 5px;
        background: url('../../../assets/images/face.png') no-repeat;
        background-size: 100% 100%;
    }
    .qrcode {
        background: url(../../../assets/images/qrbg.jpg) no-repeat;
        background-size: 100% 100%;
        width: 270px;
        height: 270px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 10px;
        position: relative;
    }
    .qrcode > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
        color: #37b7df;
    }
    .desc {
        font-size: 10px;
        text-align: center;
        color: white;
    }
    /deep/ .van-popup {
        background: transparent;
    }
    .popup {
        width: 290px;
        height: 190px;
        overflow: hidden;
        border-radius: 2px;
    }
    .popup .facebg {
        background: url(../../../assets/images/facebg.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .popup .facebg .facebtn {
        height: 80px;
        width: 80px;
        background: url(../../../assets/images/facebtn.png) no-repeat 0 7px;
        background-size: 98% 93%;
    }
    .preview {
        position: absolute;
        left: 78px;
        top: 0;
        width: 155px;
        height: 190px;
    }
    .preview .priviewcontent {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .preview .priviewcontent .closewrap {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 16px;
    }
    .preview .priviewcontent .closewrap i {
        color: white;
        font-size: 16px;
        position: absolute;
        top: 2px;
        right: 2px;
    }
    .preview .priviewcontent .previewimg {
        height: 100%;
        width: 100%;
    }
    /deep/ .van-uploader__mask {
        border-top-left-radius: 2px;
    }
    .error {
        text-align: center;
        color: white;
        font-size: 16px;
        margin-top: 40px;
    }
</style>
